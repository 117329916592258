import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404: Not found"
      description="Sorry, that page doesn't seem to exist"
    />
    <section id="introduction">
      <div className="container">
        <div className="heading">
          <h1>Sorry, that doesn't exist!</h1>
        </div>
        <p>Whatever you were looking for doesn't seem to exist, try clicking below to return home.</p>
        <Link to="/" className="btn">
          Back Home
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
